import { Modal, Spinner } from "react-bootstrap";

export default function LoadingScreen({ modalShow, loadingText }) {
	function MyVerticallyCenteredModal({ show, loadingText }) {
		return (
			<>
				<Modal
					show={show}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="loading-screen text-center"
				>
					<Modal.Body>
						<Spinner
							as="span"
							animation="border"
							role="status"
							aria-hidden="true"
						/>
						<p>{loadingText}</p>
					</Modal.Body>
				</Modal>
			</>
		);
	}
	return (
		<>
			<MyVerticallyCenteredModal
				show={modalShow}
				loadingText={loadingText}
			/>
		</>
	);
}
