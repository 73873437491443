import { useState, useEffect, useRef } from "react";
import {
	Container,
	Image,
	Row,
	Col,
	Card,
	Stack,
	Button,
} from "react-bootstrap";
import axios from "axios";
import { addDays, format } from "date-fns";
import { useAuthHeader, useSignOut } from "react-auth-kit";
import { useDispatch } from "react-redux";

import DateRangePickerComp from "../components/Date_range_component.js";
import DragDropFile from "../components/Drag_drop_file.js";
import LogoutConfirm from "../components/Logout_model.js";
import FileUploadingModal from "../components/File_uploading_modal.js";

import { Dashboard_URL, Upload_Dicom } from "../env.js";
import GetApi from "../apis/GetApi.js";
import { FileUploadSliceActions } from "../store/file_upload_slice.js";

export default function UploadFile(props) {
	let authHeader = useAuthHeader();
	const signOut = useSignOut();
	const [isOnline, setIsOnline] = useState(window.navigator.onLine);
	const [isTimeout, setisTimeout] = useState(false);

	const [dateRange, setdateRange] = useState({
		startDate: addDays(new Date(), -7),
		endDate: new Date(),
	});
	const [FilesGiven, setFilesGiven] = useState(0);
	const [DicomFiles, setDicomFiles] = useState(null);
	const [progressDicom, setprogressDicom] = useState(false);
	const [progressBarDicom, setprogressBarDicom] = useState(0);
	const [logoutModal, setlogoutModal] = useState(false);

	const dispatch = useDispatch();
	const fileCancelController = useRef();

	async function getDashBoardUserData({ loadScreen }) {
		// Starts loading page
		if (loadScreen) {
			props.loadingPageText("Getting Data");
			props.loadingPage(true);
		}

		// Set parameter and GET request with auth
		const queryParam =
			"?start_date=" +
			format(dateRange.startDate, "yyyy-MM-dd") +
			"&end_date=" +
			format(dateRange.endDate, "yyyy-MM-dd");

		const response = await GetApi({
			link: Dashboard_URL + queryParam,
			authHeader: authHeader(),
		});

		if (response.status === 401) {
			logout();
		}

		setFilesGiven(() => response.data.total_count);

		// Close loading page
		if (loadScreen) {
			props.loadingPage(false);
		}
	}

	useEffect(() => {
		const handleOnlineStatus = () => {
			setIsOnline(window.navigator.onLine);
		};

		window.addEventListener("online", handleOnlineStatus);
		window.addEventListener("offline", handleOnlineStatus);

		return () => {
			window.removeEventListener("online", handleOnlineStatus);
			window.removeEventListener("offline", handleOnlineStatus);
		};
	}, []);

	// This will fetch Files number when dates are changed on dashboard
	useEffect(() => {
		getDashBoardUserData({ loadScreen: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange]);

	// This will send Dicom file to server
	useEffect(() => {
		const sendDicom = async () => {
			const abortController = new AbortController();
			fileCancelController.current = abortController;

			// Starts loading page
			setprogressDicom(true);

			var totalSize = 0;
			var uploadedSize = 0;

			// adding 500 bit for header request
			for (let i = 0; i < DicomFiles.length; i++) {
				totalSize += DicomFiles[i].size + 500;
			}

			dispatch(FileUploadSliceActions.setTotalFile(DicomFiles.length));

			for (let i = 0; i < DicomFiles.length; i++) {
				var formData = new FormData();
				formData.append("dicom", DicomFiles[i]);

				dispatch(
					FileUploadSliceActions.appendFile({
						name: DicomFiles[i].name,
						size: DicomFiles[i].size,
						startTime: performance.now(),
						status: "Uploading",
					})
				);

				const response = await axios
					.post(Upload_Dicom, formData, {
						headers: {
							Authorization: authHeader(),
							mode: "cors",
							method: "POST",
							credentials: "include",
						},
						// eslint-disable-next-line no-loop-func
						onUploadProgress: (progressEvent) => {
							setprogressBarDicom(
								Math.round(
									((uploadedSize + progressEvent.loaded) *
										100) /
										totalSize
								)
							);
						},
						signal: abortController.signal,
						timeout: 120000,
					})
					.then(function (response) {
						return { status: response.status, data: response.data };
					})
					.catch(function (error) {
						if (axios.isCancel(error)) {
							return { status: 505, data: null };
						}
						if (error.code === "ECONNABORTED" || error.code === "ERR_NETWORK") {
							return { status: 408, data: null };
						}
						return {
							status: error.response.status,
							data: error.response.data,
						};
					});

				dispatch(FileUploadSliceActions.updateEndTime());
				uploadedSize += DicomFiles[i].size + 500;

				if (response.status === 200) {
					dispatch(FileUploadSliceActions.successfullUpload());
				} else if (response.status === 201) {
					dispatch(FileUploadSliceActions.filealreadyuploaded());
				} else if (response.status === 401) {
					dispatch(FileUploadSliceActions.reset());
					signOut();
					props.invalidPageText({
						mainline: "",
						mainlinespan: "You signed out",
						secondaryline: "Please login again with your number",
					});
					props.invalidPage(true);
					props.navigate("/login");
				} else if (response.status === 408) {
					setisTimeout(true);
					break;
				} else if (response.status === 422) {
					dispatch(FileUploadSliceActions.filetagsmissing());
				} else if (response.status === 500) {
					dispatch(FileUploadSliceActions.failedUpload());
				} else if (response.status === 505) {
					break;
				} else {
					getDashBoardUserData({ loadScreen: false });
					setprogressDicom(false);
					props.invalidPageText({
						mainline: "",
						mainlinespan: "Error code " + response.status,
						secondaryline: "Please Try again",
					});
					props.invalidPage(true);
				}
			}

			setprogressBarDicom(100);
		};
		if (DicomFiles) {
			if (isOnline) {
				sendDicom();
				setDicomFiles(null);
				setisTimeout(false);
			} else {
				setDicomFiles(null);
				getDashBoardUserData({ loadScreen: false });
				setprogressDicom(false);
				props.invalidPageText({
					mainline: "No internet connection",
					mainlinespan: "",
					secondaryline:
						"Please check your internet connection and try again",
				});
				props.invalidPage(true);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [DicomFiles]);

	function uploadFileNext() {
		setDicomFiles(null);
		getDashBoardUserData({ loadScreen: false });
		setprogressDicom(false);
		dispatch(FileUploadSliceActions.reset());
	}

	function cancelUpload() {
		fileCancelController.current.abort();
	}

	function logout() {
		signOut();
		props.validPageText({
			mainline: "Logged Out",
			mainlinespan: "Successfully",
			secondaryline: "",
		});
		props.validPage(true);
		props.navigate("/login");
	}

	function UploadPageRender() {
		let UploadPageHTML = (
			<>
				<Container fluid className="home-page">
					<Row
						className="justify-content-center m-3"
						style={{ height: "60vh" }}
					>
						<Col
							md="6"
							style={{ paddingRight: "6rem", paddingTop: "3rem" }}
						>
							{/* Top left component for Date range picker */}
							<Row>
								<Stack direction="horizontal" gap={4}>
									<h3 className="calendarWrap-text">
										File uploaded :{" "}
									</h3>
									<DateRangePickerComp
										dateRange={dateRange}
										setdateRange={setdateRange}
									/>
								</Stack>
							</Row>

							{/* Left Middle component for showing how many files are uploaded */}
							<Row className="mt-5">
								<Card className="uploaded-files-card">
									<Card.Img src="./lung_vector.png" />
									<Card.Body>
										<Card.Title>
											<h2>{FilesGiven} Chest X-Rays</h2>
										</Card.Title>
										<Card.Subtitle className="mb-2 text-muted">
											<h4>Shared with doctors</h4>
										</Card.Subtitle>
									</Card.Body>
								</Card>
							</Row>

							<Row className="mt-5">
								<h2 className="upload-box-text">
									Upload Folder with Chest X-Rays in .dcm
									Format
								</h2>
								<DragDropFile
									setDicomFiles={setDicomFiles}
									isOnline={isOnline}
									invalidPage={props.invalidPage}
									invalidPageText={props.invalidPageText}
								/>
							</Row>
						</Col>
						<Col md="6">
							<Button
								className="logout-button"
								onClick={() => setlogoutModal(true)}
							>
								Logout
							</Button>
							<Row>
								<Image
									className="mt-5 pt-6"
									src="./upload_pic.svg"
									fluid
								/>
							</Row>
						</Col>
					</Row>
				</Container>

				{progressDicom && (
					<FileUploadingModal
						modalShow={progressDicom}
						progressNow={progressBarDicom}
						nextButton={uploadFileNext}
						cancelUpload={cancelUpload}
						isOnline={isOnline}
						isTimeout={isTimeout}
					/>
				)}

				{logoutModal && (
					<LogoutConfirm
						modalShow={logoutModal}
						setmodalshow={setlogoutModal}
						logout={logout}
					/>
				)}
			</>
		);

		return UploadPageHTML;
	}

	return UploadPageRender();
}
