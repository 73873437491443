import { useState, useEffect } from "react";
import { useSignIn } from "react-auth-kit";

import OTPComponent from "../components/OTP_component";
import MobileNumberComponent from "../components/Mobile_component";

import { Send_OTP_URL, Verify_OTP_URL, Cookie_Expire_Time } from "../env.js";
import PostApi from "../apis/PostApi";

export default function MobileNumberPage(props) {
	const [OTPpage, setOTPpage] = useState(false);
	const [MobileNumber, setMobileNumber] = useState("");
	const [OTP, setOTP] = useState("");
	const signIn = useSignIn();

	useEffect(() => {
		const sendrequest = async () => {
			// Loading page will appear
			props.loadingPageText("Generating OTP");
			props.loadingPage(true);

			// Send mobile number for checking and sending OTP
			var formData = new FormData();
			formData.append("phone_number", MobileNumber);
			const response = await PostApi({
				link: Send_OTP_URL,
				bodyData: formData,
			});

			// Loading page will close
			props.loadingPage(false);

			// Error handling
			if (response.status === 200 || response.status === 201) {
				setOTPpage(true);
			} else if (response.status === 404) {
				props.invalidPageText({
					mainline: "",
					mainlinespan: "Your account doesn't exist",
					secondaryline:
						"No staff with phone number " +
						MobileNumber +
						" exists",
				});
				props.invalidPage(true);
			} else {
				props.invalidPageText({
					mainline: "",
					mainlinespan: "Server error",
					secondaryline: "Please try again",
				});
				props.invalidPage(true);
			}
		};
		if (MobileNumber !== "") {
			sendrequest();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [MobileNumber]);

	useEffect(() => {
		const sendrequest = async () => {
			// Loading page will appear
			props.loadingPageText("Getting Data");
			props.loadingPage(true);

			var formData = new FormData();
			formData.append("phone_number", MobileNumber);
			formData.append("otp", OTP);
			const response = await PostApi({
				link: Verify_OTP_URL,
				bodyData: formData,
			});

			// Loading page will close
			props.loadingPage(false);

			// Error handling
			if (response.status === 200 || response.status === 201) {
				signIn({
					token: response.data.token,
					tokenType: "Bearer",
					expiresIn: Cookie_Expire_Time,
					authState: { staff_details: response.data.staff_details },
				});
				setOTPpage(false);
				props.navigate("/");
			} else if (response.status === 401) {
				props.invalidPageText({
					mainline: "",
					mainlinespan: "OTP is Invalid",
					secondaryline: "Try again with valid OTP",
				});
				props.invalidPage(true);
			} else {
				props.invalidPageText({
					mainline: "",
					mainlinespan: "Server error",
					secondaryline: "Please try again",
				});
				props.invalidPage(true);
			}
		};
		if (OTP !== "" && MobileNumber !== "") {
			sendrequest();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [OTP]);

	return (
		<>
			{OTPpage ? (
				<OTPComponent setOTP={setOTP} />
			) : (
				<MobileNumberComponent setMobileNumber={setMobileNumber} />
			)}
		</>
	);
}
