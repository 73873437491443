import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "react-auth-kit";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import App from "./App";

import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<AuthProvider
			authType={"cookie"}
			authName={"_auth"}
			cookieDomain={window.location.hostname}
			cookieSecure={window.location.protocol === "https:"}
		>
			<BrowserRouter>
				<Provider store={store}>
					<App />
				</Provider>
			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>
);
