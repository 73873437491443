import { useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { InputGroup, Form } from "react-bootstrap";
import { createStaticRanges } from "react-date-range/src/defaultRanges";
import { AiOutlineCalendar } from "react-icons/ai";

import format from "date-fns/format";
import {
	addDays,
	startOfDay,
	endOfDay,
	startOfWeek,
	endOfWeek,
	startOfMonth,
	endOfMonth,
} from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const DateRangePickerComp = (props) => {
	// date state
	const [range, setRange] = useState([
		{
			startDate: props.dateRange.startDate,
			endDate: props.dateRange.endDate,
			key: "selection",
			autofocus: true,
		},
	]);

	const staticDataRange = createStaticRanges([
		{
			label: "Today",
			range: () => ({
				startDate: startOfDay(new Date()),
				endDate: endOfDay(new Date()),
			}),
		},
		{
			label: "Last 7 days",
			range: () => ({
				startDate: startOfDay(addDays(new Date(), -7)),
				endDate: endOfDay(new Date()),
			}),
		},
		{
			label: "Last week",
			range: () => ({
				startDate: startOfWeek(addDays(new Date(), -7)),
				endDate: endOfWeek(addDays(new Date(), -7)),
			}),
		},
		{
			label: "Last month",
			range: () => ({
				startDate: startOfMonth(addDays(new Date(), -30)),
				endDate: endOfMonth(addDays(new Date(), -30)),
			}),
		},
	]);

	// open close
	const [open, setOpen] = useState(false);

	// get the target element to toggle
	const refOne = useRef(null);

	// // event listeners
	// useEffect(() => {
	// 	document.addEventListener("keydown", hideOnEscape, true);
	// 	document.addEventListener("click", hideOnClickOutside, true);

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// useEffect(() => {
	// 	console.log("Date component date changed : " + range);
	// }, [range]);

	// // hide dropdown on ESC press
	// const hideOnEscape = (e) => {
	// 	if (open && e.key === "Escape") {
	// 		setOpen(false);
	// 		// props.setdateRange(() => ({
	// 		// 	startDate: range.startDate,
	// 		// 	endDate: range.endDate,
	// 		// }));
	// 	}
	// };

	// // Hide dropdown on outside click
	// const hideOnClickOutside = (e) => {
	// 	// e.preventDefault();
	// 	if (refOne.current && !refOne.current.contains(e.target)) {
	// 		setOpen(false);
	// 		props.setdateRange({
	// 			startDate: range[0].startDate,
	// 			endDate: range[0].endDate,
	// 		});
	// 		console.log("Here yo baby");
	// 		// console.log(range.startDate);
	// 	}
	// };

	function handleChange() {
		setOpen((open) => !open);
		props.setdateRange({
			startDate: range[0].startDate,
			endDate: range[0].endDate,
		});
	}

	return (
		<div className="calendarWrap">
			<InputGroup
				size="lg"
				className="dateinputBox"
				onClick={handleChange}
			>
				<InputGroup.Text id="inputGroup-sizing-lg" className="icon">
					<AiOutlineCalendar />
				</InputGroup.Text>
				<Form.Control
					aria-label="Large"
					aria-describedby="inputGroup-sizing-lg"
					value={`${format(
						range[0].startDate,
						"dd/MM/yyyy"
					)} - ${format(range[0].endDate, "dd/MM/yyyy")}`}
					readOnly={true}
				/>
			</InputGroup>

			<div ref={refOne}>
				{open && (
					<>
						<DateRangePicker
							onChange={(item) => setRange([item.selection])}
							editableDateInputs={false}
							moveRangeOnFirstSelection={false}
							ranges={range}
							months={1}
							direction="horizontal"
							preventSnapRefocus={false}
							staticRanges={staticDataRange}
							inputRanges={[]}
							className="calendarElement"
							maxDate={startOfDay(new Date())}
							rangeColors={["4e59e3", "#dadcf559"]}
							color={"#4e59e3"}
							renderCalen
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default DateRangePickerComp;
