import { useState, useRef } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";

export default function OTPComponent({ setOTP }) {
	const [otp, setOtp] = useState("");
	const inputRefs = useRef([]);
	const buttonFocus = useRef(null);
	const [buttonStatus, setbuttonStatus] = useState(true);

	const handleChange = (e, index) => {
		const value = e.target.value;
		setOtp((prevOtp) => {
			const newOtp = [...prevOtp];
			newOtp[index] = value;
			return newOtp;
		});

		if (value && index === 5) {
			setbuttonStatus(false);
			buttonFocus.current.focus();
		} else setbuttonStatus(true);

		if (value && inputRefs.current[index + 1]) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handleKeyDown = (e, index) => {
		if (
			e.key === "Backspace" &&
			!e.target.value &&
			inputRefs.current[index - 1]
		) {
			inputRefs.current[index - 1].focus();
		}
	};

	const handleSubmit = (e) => {
		setOTP(otp.join(""));
	};

	return (
		<>
			<Container
				className="justify-content-center align-items-center"
				style={{ paddingTop: "25vh", paddingInline: "15vw" }}
			>
				<h1 style={{ fontWeight: "700" }}>OTP Verification</h1>
				<br></br>
				<p className="text-muted" style={{ marginBottom: "0rem" }}>
					Enter OTP
				</p>
				<Form className="otp-input">
					<div className="d-flex justify-content-between">
						{[0, 1, 2, 3, 4, 5].map((index) => (
							<Form.Control
								key={index}
								type="text"
								maxLength="1"
								value={otp[index] || ""}
								onChange={(e) => handleChange(e, index)}
								onKeyDown={(e) => handleKeyDown(e, index)}
								ref={(ref) => (inputRefs.current[index] = ref)}
								className="otp-input-box"
							/>
						))}
					</div>

					<br></br>
					<div className="text-center">
						<Button
							variant="primary"
							type="button"
							size="lg"
							className="submit-button"
							onClick={handleSubmit}
							ref={buttonFocus}
							disabled={buttonStatus}
						>
							Submit OTP <FaArrowRight />
						</Button>
					</div>
				</Form>
			</Container>
		</>
	);
}
