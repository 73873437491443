import { useRef, useState } from "react";
import {
	Container,
	Form,
	Button,
	InputGroup,
	FormControl,
} from "react-bootstrap";
import { FaMobileAlt, FaArrowRight } from "react-icons/fa";

export default function MobileNumberComponent({ setMobileNumber }) {
	const [validated, setValidated] = useState(false);
	const [mobileNumber, setmobileNumber] = useState("");
	const buttonFocus = useRef(false);

	// This will insert gaps in the mobile number field and change focus to button when 10 digit par given
	const handleChange = (e) => {
		const inputVal = e.target.value.replace(/\D/g, "");
		let formattedVal = inputVal.replace(/(\d{1})/g, "$1 ");

		if (formattedVal.charAt(formattedVal.length - 1) === " ") {
			formattedVal = formattedVal.slice(0, -1);
		}
		setmobileNumber(formattedVal);

		if (inputVal.length === 10) {
			setValidated(true);
			buttonFocus.current.focus();
		} else {
			setValidated(false);
		}
	};

	const handleSubmit = (e) => {
		let formatedNumber = mobileNumber.replace(/ /g, "");
		if (formatedNumber.length === 10) setMobileNumber(formatedNumber);
	};

	return (
		<>
			<Container
				className="justify-content-center align-items-center"
				style={{ paddingTop: "25vh", paddingInline: "15vw" }}
			>
				<h1 style={{ fontWeight: "700" }}>Log In</h1>
				<br></br>
				<p className="text-muted" style={{ marginBottom: "0rem" }}>
					Enter Mobile Number
				</p>
				<Form noValidate validated={validated}>
					<InputGroup className="mobile-input" size="lg">
						<InputGroup.Text>
							<FaMobileAlt size={30} />
						</InputGroup.Text>
						<FormControl
							placeholder="Mobile Number"
							maxLength={18}
							value={mobileNumber}
							onChange={(e) => handleChange(e)}
							style={{ fontSize: "1.4rem", padding: "1rem" }}
						/>
						<FormControl.Feedback type="invalid">
							Please type valid number
						</FormControl.Feedback>
					</InputGroup>

					<br></br>
					<div className="text-center">
						<Button
							variant="primary"
							type="button"
							size="lg"
							className="submit-button"
							onClick={handleSubmit}
							ref={buttonFocus}
						>
							Get OTP <FaArrowRight />
						</Button>
					</div>
				</Form>
			</Container>
		</>
	);
}
