import { Button, Modal } from "react-bootstrap";

export default function LogoutConfirm(props) {
	return (
		<>
			<Modal
				show={props.modalShow}
				onHide={() => props.setmodalshow(false)}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="text-center"
			>
				<Modal.Body>
					<h4 style={{ marginBlock: "2rem" }}>
						Are you sure you would like to{" "}
						<span style={{ color: "rgba(180, 41, 41, 1)" }}>
							log out ?
						</span>
					</h4>
					<Button
						className="btn btn-danger"
						onClick={() => props.logout()}
						style={{
							backgroundColor: "rgba(180, 41, 41, 1)",
							marginInline: "2rem",
							paddingInline: "2rem",
						}}
					>
						Yes
					</Button>
					<Button
						className="btn"
						aria-label="Close"
						onClick={() => props.setmodalshow(false)}
						style={{
							backgroundColor: "#7B7B7B",
							marginInline: "2rem",
							paddingInline: "2rem",
						}}
					>
						No
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
}
