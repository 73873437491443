import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import {
	AiOutlineClose,
	AiOutlineFileExclamation,
	AiOutlineDiff,
	AiOutlineCheckCircle,
	AiOutlineCloseCircle,
} from "react-icons/ai";

export default function FileUploadingModal({
	modalShow,
	progressNow,
	nextButton,
	cancelUpload,
	isOnline,
	isTimeout,
}) {
	const files = useSelector((state) => state.fileUpload.Files);
	const uploadedFiles = useSelector(
		(state) => state.fileUpload.uploadedTotalFile
	);
	const totalFiles = useSelector((state) => state.fileUpload.TotalFile);
	return (
		<>
			<Modal
				show={modalShow}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="loading-screen text-center"
			>
				<Modal.Body>
					{progressNow >= 100 && (
						<Button
							className="btn-next"
							onClick={() => nextButton()}
						>
							<AiOutlineClose size={30} />
						</Button>
					)}

					{progressNow < 100 ? (
						<>
							<h1>
								<span>{uploadedFiles}/</span>
								{totalFiles}
							</h1>
							<p>
								<span>Files uploaded </span>from the Folder
							</p>

							<ProgressBar
								animated={isOnline}
								now={progressNow}
							/>

							<Button
								className="btn-cancel"
								onClick={() => cancelUpload()}
							>
								Cancel Remaining Uploads
							</Button>
						</>
					) : isOnline && !isTimeout ? (
						<>
							<AiOutlineCheckCircle
								color="#208114"
								size={"100px"}
							/>
							<p>
								<span>
									{uploadedFiles}/{totalFiles} Files uploaded{" "}
								</span>
								from the Folder
							</p>
							<p className="muted">
								All Possible Files have been Uploaded
							</p>
						</>
					) : (
						<>
							<AiOutlineCloseCircle
								color="b42929"
								size={"100px"}
							/>
							<p>Internet not connected / Connection timeout</p>
							<p>
								<span>
									{uploadedFiles}/{totalFiles} Files uploaded{" "}
								</span>
								from the Folder
							</p>
							<p className="muted">
								Remaining files you can be uploaded when stable
								internet is available.
							</p>
						</>
					)}

					<hr></hr>

					<Container
						style={{
							maxHeight: "20rem",
							overflow: "scroll",
						}}
					>
						{files.map((file, i) => {
							if (file.status === "Already Uploaded")
								return (
									<Row key={i} className="file-name-card">
										<Col
											xs={8}
											className="file-name-card-content"
										>
											<h3>
												{file.name.length > 20
													? file.name.slice(0, 20) +
													  "..."
													: file.name}
											</h3>
											{file.status ===
											"Already Uploaded" ? (
												<h4>
													This file is already
													uploaded
												</h4>
											) : file.status ===
											  "Tags Missing" ? (
												<h4>
													Mandatory fields are missing
													(SOPInstanceUID)
												</h4>
											) : (
												<h4>
													There might be some problem
													with the file. Please try
													again.
												</h4>
											)}
										</Col>

										<Col
											xs={4}
											className="file-name-card-check"
										>
											{file.status ===
											"Already Uploaded" ? (
												<>
													<AiOutlineDiff
														color={
															"rgba(180, 41, 41, 1)"
														}
														size={"40px"}
													/>
												</>
											) : file.status ===
											  "Tags Missing" ? (
												<>
													<AiOutlineFileExclamation
														color={
															"rgba(180, 41, 41, 1)"
														}
														size={"40px"}
													/>
												</>
											) : (
												<AiOutlineClose
													color={
														"rgba(180, 41, 41, 1)"
													}
													size={"40px"}
												/>
											)}
										</Col>
									</Row>
								);
							return null;
						})}
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
}
