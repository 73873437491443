import { createSlice } from "@reduxjs/toolkit";

const FileUploadSlice = createSlice({
	name: "Dicom_file_upload",
	initialState: {
		TotalFile: 0,
		uploadedTotalFile: 0,
		uploadedSize: 0,
		totalSize: 0,
		Files: [],
	},
	reducers: {
		reset: (state) => {
			state.TotalFile = 0;
			state.uploadedTotalFile = 0;
			state.uploadedSize = 0;
			state.totalSize = 0;
			state.Files = [];
		},
		appendFile: (state, action) => {
			state.Files.push(action.payload);
		},
		setTotalFile: (state, action) => {
			state.TotalFile += action.payload;
		},
		setUploadedFileSize: (state, action) => {
			state.uploadedSize += action.payload;
		},
		successfullUpload: (state) => {
			state.Files[state.Files.length - 1].status = "Uploaded";
			state.uploadedTotalFile += 1;
		},
		filealreadyuploaded: (state) => {
			state.Files[state.Files.length - 1].status = "Already Uploaded";
			state.uploadedTotalFile += 1;
		},
		filetagsmissing: (state) => {
			state.Files[state.Files.length - 1].status = "Tags Missing";
		},
		failedUpload: (state) => {
			state.Files[state.Files.length - 1].status = "Failed";
		},
		updateEndTime: (state) => {
			state.Files[state.Files.length - 1].endTime = performance.now();
		},
	},
});

export const FileUploadSliceActions = FileUploadSlice.actions;
export default FileUploadSlice.reducer;
