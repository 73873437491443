import axios from "axios";

export default async function PostApi(props) {
	try {
		let res = await axios
			.post(props.link, props.bodyData, {
				headers: {
					Authorization: props.authHeader,
					mode: "cors",
					method: "POST",
					credentials: "same-origin",
					acessControlAllowOrigin: "*",
					acessControlAllowMethod: "GET, POST",
				},
			})
			.then(function (response) {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
		return res;
	} catch (error) {
		return { status: 500, data: error };
	}
}
