import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

function NavBar() {
	return (
		<Navbar>
			<Container fluid className="justify-content-between">
				<div>
					<img
						src="/Emblem_of_Madhya_Pradesh.png"
						className="d-inline-block align-top"
						alt="MP icon"
					/>
					<img
						src="/Blank_space.png"
						className="d-inline-block align-top"
						alt="NHM icon"
					/>
				</div>
				<h1>TBScan</h1>
				<div>
					<img
						src="/Emblem_of_NHM.png"
						className="d-inline-block align-top"
						alt="NHM icon"
					/>
					<img
						src="/Emblem_of_NTB.png"
						className="d-inline-block align-top"
						alt="NTB icon"
					/>
				</div>
			</Container>
		</Navbar>
	);
}

export default NavBar;
