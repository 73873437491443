import { useRef, useState } from "react";
import { Button, Card, Image } from "react-bootstrap";

export default function DragDropFile(props) {
	const [dragActive, setDragActive] = useState(false);
	const fileUploadButtonRef = useRef(null);

	// handle drag events to set Dicom file only
	function handleDrag(e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	}

	function handleDrop(e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		console.log("Files dropped");
		console.log(e.dataTransfer.files);
		// handleFolderUpload(e.dataTransfer.files);
	}

	function handleClick() {
		if (props.isOnline) fileUploadButtonRef.current.click();
		else {
			props.invalidPageText({
				mainline: "No internet connection",
				mainlinespan: "",
				secondaryline:
					"Please check your internet connection and try again",
			});
			props.invalidPage(true);
		}
	}

	const handleUpload = async (file) => {
		props.setDicomFiles(file);
	};

	const handleFolderUpload = async (folder) => {
		var dicom_files = [];

		for (const file of folder) {
			if (file.name.endsWith(".dcm") || file.name.endsWith(".dicom")) {
				dicom_files.push(file);
			}
		}

		if (dicom_files.length > 0) {
			await handleUpload(dicom_files);
		} else {
			alert(
				"No DICOM file found in the folder, please select folder with DICOM file only"
			);
		}
	};

	const handleInputChange = (event) => {
		const folder = event.target.files;
		handleFolderUpload(folder);
	};

	return (
		<>
			<Card
				className="upload-box text-center"
				onSubmit={(e) => e.preventDefault()}
				onClick={handleClick}
				onDragEnter={handleDrag}
				onDrop={handleDrop}
			>
				<form>
					<input
						ref={fileUploadButtonRef}
						type="file"
						webkitdirectory="true"
						directory="true"
						mozdirectory="true"
						onChange={handleInputChange}
						onClick={(event) => {
							event.target.value = "";
						}}
						hidden
					></input>
					<p>
						<Image
							src="./cloud_upload.svg"
							height="90px"
							width="90px"
						/>
					</p>
					<Button size="lg">Browse a Folder</Button>
					<p className="text-muted">
						We only supports .dcm and Dicom files.
					</p>
					{!props.isOnline && <p>Internet not available</p>}
				</form>
			</Card>
		</>
	);
}
