import { configureStore } from "@reduxjs/toolkit";
import FileUploadSlice from "./file_upload_slice";

const store = configureStore({
	reducer: {
		fileUpload: FileUploadSlice,
	},
});

export default store;
