import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";

import NavBar from "./components/NavBar";
import LoadingScreen from "./components/Loading_screen.js";
import InvalidError from "./components/Invalid_error.js";
import ValidError from "./components/Valid_error.js";

import MobileNumberPage from "./pages/Mobile_number";
import UploadFile from "./pages/Upload_image";

function App() {
	const [loadingScreen, setloadingScreen] = useState(false);
	const [loadingScreenText, setloadingScreenText] = useState("");

	const [invalidScreen, setinvalidScreen] = useState(false);
	const [invalidErrorScreenText, setinvalidErrorScreenText] = useState({
		mainline: "",
		mainlinespan: "",
		secondaryline: "",
	});
	const [validScreen, setvalidScreen] = useState(false);
	const [validErrorScreenText, setvalidErrorScreenText] = useState({
		mainline: "",
		mainlinespan: "",
		secondaryline: "",
	});

	let navigate = useNavigate();

	return (
		<>
			<NavBar />

			<Routes>
				<Route
					path="/login"
					element={
						<MobileNumberPage
							loadingPage={setloadingScreen}
							loadingPageText={setloadingScreenText}
							invalidPage={setinvalidScreen}
							invalidPageText={setinvalidErrorScreenText}
							navigate={navigate}
						/>
					}
				/>
				<Route
					path="/"
					element={
						<RequireAuth loginPath={"/login"}>
							<UploadFile
								loadingPage={setloadingScreen}
								loadingPageText={setloadingScreenText}
								invalidPage={setinvalidScreen}
								invalidPageText={setinvalidErrorScreenText}
								validPage={setvalidScreen}
								validPageText={setvalidErrorScreenText}
								navigate={navigate}
							/>
						</RequireAuth>
					}
				/>
			</Routes>

			{loadingScreen && (
				<LoadingScreen
					modalShow={true}
					loadingText={loadingScreenText}
				/>
			)}

			{invalidScreen && (
				<InvalidError
					mainline={invalidErrorScreenText.mainline}
					mainlinespan={invalidErrorScreenText.mainlinespan}
					secondaryline={invalidErrorScreenText.secondaryline}
					modalShow={invalidScreen}
					setmodalshow={setinvalidScreen}
				/>
			)}

			{validScreen && (
				<ValidError
					mainline={validErrorScreenText.mainline}
					mainlinespan={validErrorScreenText.mainlinespan}
					secondaryline={validErrorScreenText.secondaryline}
					modalShow={validScreen}
					setmodalshow={setvalidScreen}
				/>
			)}
		</>
	);
}

export default App;
