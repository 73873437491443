import axios from "axios";

export default async function GetApi(props) {
	try {
		let res = await axios
			.get(props.link, {
				headers: {
					Authorization: props.authHeader,
					mode: "cors",
					nethod: "GET",
					credentials: "same-origin",
				},
			})
			.then(function (response) {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
		return res;
	} catch (error) {
		return { status: 500, data: error };
	}
}
