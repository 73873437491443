import { Button, Modal } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";

export default function InvalidError(props) {
	return (
		<>
			<Modal
				show={props.modalShow}
				onHide={() => props.setmodalshow(false)}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="text-center"
			>
				<Modal.Body>
					<Button
						className="btn-close"
						aria-label="Close"
						onClick={() => props.setmodalshow(false)}
					></Button>
					<AiOutlineExclamationCircle
						color="rgba(180, 41, 41, 1)"
						size="80px"
					/>
					<h3>
						{props.mainline + " "}
						<span style={{ color: "rgba(180, 41, 41, 1)" }}>
							{props.mainlinespan}
						</span>
					</h3>
					<p>{props.secondaryline}</p>
				</Modal.Body>
			</Modal>
		</>
	);
}
